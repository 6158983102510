import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import { getColor, elevations } from '../../css/primitives';

export const styles = StyleSheet.create({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `none`,
    border: `none`,
    margin: '0 8px',
    color: getColor('white'),
    fontWeight: '600',
    borderRadius: '2px',
    cursor: 'pointer',
    textDecoration: 'none',
    ':disabled': {
      cursor: 'not-allowed'
    }
  },

  primary: {
    ...elevations[0],
    backgroundColor: getColor('primary'),
    ':hover': {
      backgroundColor: getColor('blue5')
    },
    ':active': {
      backgroundColor: getColor('blue6')
    },
    ':disabled': {
      backgroundColor: getColor('primary', 0.55),
    },
    ':disabled:hover': {
      backgroundColor: getColor('primary', 0.55),
    }
  },

  secondary: {
    ...elevations[0],
    backgroundColor: getColor('white'),
    color: getColor('primary'),
    border: `1px solid ${getColor('primary')}`,
    ':hover': {
      backgroundColor: getColor('gray1'),
      borderColor: getColor('blue5')
    },
    ':active': {
      backgroundColor: getColor('gray2'),
      borderColor: getColor('blue6')
    },
    ':disabled': {
      color: getColor('primary', 0.55),
      borderColor: getColor('primary', 0.55),
    },
    ':disabled:hover': {
      color: getColor('primary', 0.55),
      borderColor: getColor('primary', 0.55),
    }
  },

  muted: {
    ...elevations[0],
    backgroundColor: getColor('gray1'),
    color: getColor('black', 0.6),
    ':hover': {
      backgroundColor: getColor('gray3')
    },
    ':active': {
      backgroundColor: getColor('gray4')
    },
    ':disabled': {
      color: getColor('black', 0.45),
    },
    ':disabled:hover': {
      color: getColor('black', 0.45),
      backgroundColor: getColor('gray1'),
    }
  },
  inline: {
    backgroundColor: getColor('white', 0.25),
    color: getColor('black', 0.9),
    padding: '4px',
    ':hover': {
      backgroundColor: getColor('gray1')
    },
    ':active': {
      backgroundColor: getColor('gray1')
    },
    ':disabled': {
      color: getColor('black', 0.55),
    }
  },

  large: {
    fontSize: '20px',
    padding: '8px 16px'
  },

  default: {
    fontSize: '14px',
    padding: `7.5px 16px`,
  },

  small: {
    fontSize: '13px',
    padding: '4px 8px'
  }
});


class Button extends Component {
  render() {
    const { strength, size='default' } = this.props;
    const incomingClass = this.props.className ? ` ${this.props.className}` : '';
    return (
      <button
          onClick={this.props.onClick}
          type={this.props.type || 'button'}
          disabled={this.props.disabled}
          data-cy={this.props['data-cy']}
          className={`${css(styles.button, styles[strength], styles[size], this.props.styles)}${incomingClass}`}>
        {this.props.children}
      </button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  stregth: PropTypes.oneOf(['primary', 'secondary', 'muted', 'inline']),
  size: PropTypes.oneOf(['large', 'default', 'small']),

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
  type: PropTypes.oneOf(['button', 'submit', 'reset', 'menu']),
  className: PropTypes.string
};

Button.defaultProps = {
  strength: 'primary'
};

export default Button;
