import { connect } from 'react-redux';

import AppComponent from '../components/App';
import { fetchLatestVersion, fetchUserDetails } from '../actions';

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails() {
      dispatch(fetchUserDetails());
    },
    fetchLatestVersion() {
      dispatch(fetchLatestVersion());
    }
  };
};

const mapStateToProps = (state) => {
  return state;
};

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent);

export default App;
