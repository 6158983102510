// USER ACTIONS
export const CONFIRM_CARD_ADDED = 'CONFIRM_CARD_ADDED';
export const CONFIRM_CARD_REMOVED = 'CONFIRM_CARD_REMOVED';
export const CONFIRM_EMAIL_CONFIRMED = 'CONFIRM_EMAIL_CONFIRMED';
export const CONFIRM_PROMO_DISMISSED = 'CONFIRM_PROMO_DISMISSED';
export const CONFIRM_PROMO_IMPRESSION = 'CONFIRM_PROMO_IMPRESSION';
export const CONFIRM_PUSH_SUBSCRIPTION = 'CONFIRM_PUSH_SUBSCRIPTION';
export const CONFIRM_PUSH_UNSUBSCIBED = 'CONFIRM_PUSH_UNSUBSCIBED';
export const CONFIRM_SUBSCRIPTION_CANCELED = 'CONFIRM_SUBSCRIPTION_CANCELED';
export const CONFIRM_SET_REMINDER_TIME = 'CONFIRM_SET_REMINDER_TIME';
export const CONFIRM_USER_REGISTERED = 'CONFIRM_USER_REGISTERED';
export const CONFIRM_USER_PLAN_UPDATED = 'CONFIRM_USER_PLAN_UPDATED';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';

// ENTRIES ACTIONS
export const CANCEL_ENTRY_EDITS = 'CANCEL_ENTRY_EDITS';
export const CLEAR_ENTRIES_CACHE = 'CLEAR_ENTRIES_CACHE';
export const CONFIRM_ENTRIES_UPLOADED = 'CONFIRM_ENTRIES_UPLOADED';
export const CONFIRM_ENTRY_CREATED = 'CONFIRM_ENTRY_CREATED';
export const CONFIRM_ENTRY_IMAGES_DELETED = 'CONFIRM_ENTRY_IMAGES_DELETED';
export const CONFIRM_ENTRY_SYNC = 'CONFIRM_ENTRY_SYNC';
export const CONFIRM_IMAGE_UPLOADED = 'CONFIRM_IMAGE_UPLOADED';
export const CREATE_NEW_ENTRY = 'CREATE_NEW_ENTRY';
export const CONFIRM_SUGGESTED_ENTRY_SAVED = 'CONFIRM_SUGGESTED_ENTRY_SAVED';
export const CONFIRM_SUGGESTED_ENTRY_SYNC = 'CONFIRM_SUGGESTED_ENTRY_SYNC';
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const CONFIRM_TOPIC_CREATED = 'CONFIRM_TOPIC_CREATED';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const EDIT_ENTRY = 'EDIT_ENTRY';
export const RECEIVE_DAILY_REVIEW = 'RECEIVE_DAILY_REVIEW';
export const RECEIVE_ENTRIES = 'RECEIVE_ENTRIES';
export const RECEIVE_ON_THIS_DAY_ENTRIES = 'RECEIVE_ON_THIS_DAY_ENTRIES';
export const RECEIVE_TOPIC_ENTRIES = 'RECEIVE_TOPIC_ENTRIES';
export const RECEIVE_TOPICS_FOR_QUERY = 'RECEIVE_TOPICS_FOR_QUERY';
export const REQUEST_LATEST_ENTRIES = 'REQUEST_LATEST_ENTRIES';
export const REQUEST_ON_THIS_DAY_ENTRIES = 'REQUEST_ON_THIS_DAY_ENTRIES';
export const REQUEST_TOPIC_ENTRIES = 'REQUEST_TOPIC_ENTRIES';
export const SAVE_DRAFT = 'SAVE_DRAFT';
export const SYNC_DIRTY_ENTRY = 'SYNC_DIRTY_ENTRY';
export const UPLOAD_ENTRIES = 'UPDATE_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const UPDATE_SUGGESTED_ENTRY = 'UPDATE_SUGGESTED_ENTRY';

// CONNECT ACTIONS
export const CONFIRM_STRAVA_CONNECT = 'CONFIRM_STRAVA_CONNECT';
export const CONFIRM_INSTAGRAM_CONNECT = 'CONFIRM_INSTAGRAM_CONNECT';
export const CONFIRM_INSTAGRAM_ACCESS = 'CONFIRM_INSTAGRAM_ACCESS';
export const CONFIRM_FACEBOOK_CONNECT = 'CONFIRM_FACEBOOK_CONNECT';

// OTHER
export const RECEIVE_LATEST_VERSION = 'RECEIVE_LATEST_VERSION';
