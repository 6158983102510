import * as actions from '../actionTypes';


const connectedApps = (
  state = {
    stravaIsConnected: false,
    instagramIsConnected: false
  },
  action
) => {
  switch (action.type) {
  case actions.CONFIRM_STRAVA_CONNECT:
    return {
      ...state,
      stravaIsConnected: true
    };
  case actions.CONFIRM_INSTAGRAM_CONNECT:
    return {
      ...state,
      instagramIsConnected: true
    };
  default:
    return state;
  }
};

export default connectedApps;
