import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from './lib/Button';
import { elevations } from '../css/primitives';

const styles = StyleSheet.create({
  prompt: {
    ...elevations[0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
    position: 'fixed',
    bottom: '0',
    left: 'calc(50% - 150px)',
    width: '300px',
    backgroundColor: '#FFF',
    borderRadius: '2px',
  }
});

const prompt = (evt) => {
  evt.prompt();

  evt.userChoice.then(choice => {
    // TODO: coice === 'accepted' show a toast about how the app is installing
    // this.setState({ deferredHomescreenPrompt: null });
  });
};

const HomescreenPrompt = ({ deferredEvt }) => {
  return <div className={css(styles.prompt)}>
    <Button strength="secondary" onClick={() => prompt(deferredEvt)}>Install</Button> Caneta instantly!
  </div>;
};
export default HomescreenPrompt;