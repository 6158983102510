import 'whatwg-fetch';
import idbKeyval from 'idb-keyval';
export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://caneta.co/api' : 'http://localhost:3000/api';
export const TOKEN_LOCAL_STORAGE_KEY = 'caneta-auth-token';
export const DRAFT_LOCAL_STORAGE_KEY = 'caneta-draft';

const reminderTimeMap = {
  'morning': 7,
  'afternoon': 13,
  'evening': 21
};

let token = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) || '';

const getFormattedUserTimezone = () => {
  const now = new Date();
  const tzOffset = now.getTimezoneOffset();
  const hours = Math.abs(Math.floor(tzOffset/60));
  const minutes = tzOffset % 60;
  // For some reason, the formatted sign is the opposite of the offset sign
  const sign = tzOffset > 0 ? '-' : '+';
  return encodeURIComponent(`${sign}${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`);
};

const getReminderTime = () => {
  return idbKeyval.get('reminderTime');
};

const getJsonFromResponse = response => ( response.json().then(res => res.data) );

/*
const validators = {
  type(value, type) {
    return typeof value === type;
  },

  maxLength(value, length) {
    return value.length <= length;
  }
};

const validate = (value, requirements, errMessage) => {
  Object.keys(requirements).reduce((err, validator) => {
    try {
      return validators[validator](value, requirements[validator]) ? errMessage : err;
    } catch (err) {
      return errMessage;
    }
  }, null);
};
*/

const formDataFromObject = (obj, form=new FormData()) => {
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach(val => form.append(`${key}`, val));
    } else {
      form.append(key, obj[key]);
    }
  });
  return form;
};

export const BY_DATE = 'date';
export const ON_THIS_DAY = 'on-this-day';
export const BY_TOPIC = 'topic';
const api = {
  baseUrl,
  processResponse(response) {
    return getJsonFromResponse(response)
    .then(data => {
      if (data && data.requireLogin) {
        return this.logout()
        .then(() => data);
      }
      return data;
    });
  },

  setToken(newToken) {
    token = newToken;
    window.localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, newToken);
  },

  clearPrivateData() {
    window.localStorage.clear(TOKEN_LOCAL_STORAGE_KEY);
    window.localStorage.clear(DRAFT_LOCAL_STORAGE_KEY);
  },

  getPostJsonHeaders(headers={}) {
    return {
      ...headers,
      'Content-Type': 'application/json'
    };
  },

  getTokenHeaders(headers={}) {
    return {
      ...headers,
      'x-access-token': token
    };
  },

  graphql(query, variables={}) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({ query, variables });
    return fetch(
      `${baseUrl}/graphql`,
      {
        method: 'POST',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  upload(file, onProgress) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      formData.append('image', file);
      xhr.open('POST', `${baseUrl}/upload/image`);
      xhr.upload.addEventListener('progress', onProgress);
      xhr.onload = e => {
        resolve(JSON.parse(xhr.responseText));
      };
      xhr.onerror = () => { reject(); };
      xhr.send(formData);
    });
  },

  export() {
    const headers = this.getTokenHeaders();
    return fetch(
      `${baseUrl}/export`,
      {
        method: 'GET',
        headers
      }
    )
      .then(async res => {
        const blob = await res.blob();
        const objURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objURL;
        a.download = 'journal.csv';
        a.dispatchEvent(new MouseEvent('click'));
      });
  },

  createGiftOrder(giftDetails) {
    const headers = this.getPostJsonHeaders({});
    const body = JSON.stringify({ email: giftDetails.gifterEmail });
    return fetch(
      `${baseUrl}/gift/order`,
      {
        method: 'POST',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  sendGift(giftDetails, order) {
    const headers = this.getPostJsonHeaders({});
    const body = JSON.stringify({
      ...giftDetails,
      orderId: order.id
    });
    return fetch(
      `${baseUrl}/gift/purchase`,
      {
        method: 'POST',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  signup(userDetails) {
    // const { firstName, lastName, email, password } = userDetails;
    // const firstNameError = validate(firstName, {type: 'string', maxLength: 255}, 'First Name is required, and must be less than 255 characters');
    // const lastNameError = validate(lastName, {type: 'string', maxLength: 255}, 'Last Name is required, and must be less than 255 characters');
    // const emailError = validate(email, {type: 'string', maxLength: 255}, 'Email is required, and must be less than 255 characters');
    // const passwordError = validate(password, {type: 'string', maxLength: 255}, 'Password is required, and must be less than 255 characters');
    const headers = this.getPostJsonHeaders({});
    return fetch(
      `${baseUrl}/signup`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(userDetails)
      }
    ).then(this.processResponse);
    // TODO Handle signup errors (e.g. repeat email address)
  },

  login(email, password) {
    const headers = this.getPostJsonHeaders({});
    const body = JSON.stringify({email, password});
    return fetch(
      `${baseUrl}/auth`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(response => {
      if (response.status === 200) {
        return response.json().then(res => {
          return getReminderTime().then(reminderTime => {
            this.setToken(res.data.token);
            res.data.user.reminderTime = reminderTime;
            return res.data.user;
          });
        });
      } else {
        throw new Error(response.statusText);
      }
    });
  },

  logout() {
    return new Promise(resolve => {
      this.clearPrivateData();
      resolve();
    });
  },

  saveDraft(draft) {
    window.localStorage.setItem(DRAFT_LOCAL_STORAGE_KEY, draft);
  },

  requestPasswordReset(email) {
    const headers = this.getPostJsonHeaders({});
    const body = JSON.stringify({ email });
    return fetch(
      `${baseUrl}/request-password-reset`,
      {
        method: 'POST',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  updatePassword({ newPassword, nonce, resetCode }) {
    const headers = this.getPostJsonHeaders({});
    const body = JSON.stringify({ newPassword });
    return fetch(
      `${baseUrl}/user/change-password/${resetCode}/${nonce}`,
      {
        method: 'PUT',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  addCard(paymentToken) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({ paymentToken });
    return fetch(
      `${baseUrl}/user/payments/source`,
      {
        method: 'PUT',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  removeCard(cardId) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({ cardId });
    return fetch(
      `${baseUrl}/user/payments/source`,
      {
        method: 'DELETE',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  updateUserPlan(subscriptionPlan) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({ subscriptionPlan });
    return fetch(
      `${baseUrl}/user/subscription`,
      {
        method: 'PUT',
        headers,
        body
      }
    )
    .then(this.processResponse);
  },

  cancelSubscription() {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    return fetch(
      `${baseUrl}/user/subscription`,
      {
        method: 'DELETE',
        headers
      }
    )
    .then(this.processResponse);
  },

  fetchUserDetails() {
    const fetchUrl = `${baseUrl}/user/`;
    const headers = this.getTokenHeaders();
    return fetch(
      fetchUrl,
      {
        method: 'GET',
        headers
      }
    )
    .then(this.processResponse)
    .then(json => {
      return getReminderTime().then(reminderTime => {
        return {
          ...json,
          reminderTime
        };
      });
    });
  },

  fetchEntries(by) {
    const fetchUrl = `${baseUrl}/entry`;
    const headers = this.getTokenHeaders();
    switch(by) {
    case BY_DATE:
    case ON_THIS_DAY:
      const userTz = getFormattedUserTimezone();
      return (month, day, page=1) => {
        return fetch(
          `${fetchUrl}/${ON_THIS_DAY}/${month}/${day}?userTz=${userTz}&page=${page}&reverse=true`,
          {
            method: 'GET',
            headers
          }
        ).then(this.processResponse);
      };
    case BY_TOPIC:
      return (topic, page=1) => {
        return fetch(
          `${baseUrl}/topic/${topic}/entries?page=${page}&reverse=true`,
          {
            method: 'GET',
            headers
          }
        ).then(this.processResponse);
      };
    default:
      return (page=1) => {
        return fetch(
          `${fetchUrl}?page=${page}&reverse=true`,
          {
            method: 'GET',
            headers
          }
        ).then(this.processResponse);
      };
    }
  },

  createEntry(values) {
    const { text, tmpId, date='', topics=null } = values;
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify(
      {
        text,
        date,
        tmpId,
        topics
      }
    );
    return fetch(
      `${baseUrl}/entry`,
      {
        method: 'POST',
        body,
        headers
      }
    ).then(this.processResponse);
  },

  uploadImage(image, entryId) {
    const headers = this.getTokenHeaders();
    const body = formDataFromObject({ image });
    return fetch(
      `${baseUrl}/entry/${entryId}/image`,
      {
        method: 'POST',
        body,
        headers
      }
    ).then(this.processResponse);
  },

  deleteEntryImages(entryId) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    return fetch(
      `${baseUrl}/entry/${entryId}/images`,
      {
        method: 'DELETE',
        headers
      }
    ).then(this.processResponse);
  },

  deleteEntryImage(imageId) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    return fetch(
      `${baseUrl}/entry/${imageId}/image`,
      {
        method: 'DELETE',
        headers
      }
    ).then(this.processResponse);
  },

  uploadEntries(file) {
    const headers = this.getTokenHeaders();
    const body = formDataFromObject({ file });
    return fetch(
      `${baseUrl}/entry/import`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  updateEntry(entry) {
    const { id, text, date, topics=null, images=[], deleteImages=null } = entry;
    const body = JSON.stringify(
      {
        text,
        date,
        topics,
        images,
        deleteImages
      }
    );
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    return fetch(
      `${baseUrl}/entry/${id}`,
      {
        method: 'PUT',
        body,
        headers
      }
    ).then(this.processResponse);
  },

  deleteEntry(id) {
    const headers = this.getTokenHeaders();
    return fetch(
      `${baseUrl}/entry/${id}`,
      {
        method: 'DELETE',
        headers
      }
    ).then(this.processResponse);
  },

  createTopic(name) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({name});
    return fetch(
      `${baseUrl}/topic`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  queryTopics(q) {
    const headers = this.getTokenHeaders();
    return fetch(
      `${baseUrl}/topic/find/${q}`,
      {
        method: 'GET',
        headers
      }
    ).then(this.processResponse);
  },


  // External APIs
  connectStrava(code) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({code});
    return fetch(
      `${baseUrl}/connect/strava`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  connectInstagram(code) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({code});
    return fetch(
      `${baseUrl}/connect/instagram`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  connectGooglePhotos(code) {
    return this.graphql(`
      mutation ConnectGooglePhotos($code: String!) {
        connectGooglePhotos(code: $code) {
          isConnected
        }
      }
    `, { code });
  },

  setFacebookAccessToken(authResponse) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({
      accessToken: authResponse.accessToken,
      userID: authResponse.userID
    });
    return fetch(
      `${baseUrl}/connect/facebook`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  deleteFacebookAccessToken(accessToken) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({
      accessToken
    });
    return fetch(
      `${baseUrl}/connect/facebook`,
      {
        method: 'DELETE',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  registerPushSubscription(subscription, reminderTime) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({
      subscription,
      notificationTime: reminderTime && reminderTimeMap[reminderTime],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
     });
    return fetch(
      `${baseUrl}/push/web`,
      {
        method: 'POST',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  updatePushSubscription(subscription, notificationTime) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({
      subscription,
      notificationTime,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
     });
    return fetch(
      `${baseUrl}/push/web`,
      {
        method: 'PUT',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  unregisterPushSubscription(subscription) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify(subscription);
    return fetch(
      `${baseUrl}/push/web`,
      {
        method: 'DELETE',
        headers,
        body
      }
    ).then(this.processResponse);
  },

  setReminderTime(subscription, dayPart) {
    return this.updatePushSubscription(subscription, reminderTimeMap[dayPart])
    .then(() => {
      return idbKeyval.set('reminderTime', dayPart)
    });
  },

  confirmEmail(confirmationCode) {
    return fetch(
      `${baseUrl}/confirm-email/${confirmationCode}`,
      {
        method: 'PUT'
      }
    ).then(this.processResponse);
  },

  unsubscribeFromEmails(nonce) {
    return fetch(
      `${baseUrl}/email/unsubscribe/${nonce}`,
      {
        method: 'PUT'
      }
    ).then(this.processResponse);
  },

  fetchLatestVersion() {
    return fetch(
      `${baseUrl}/client-version/web`,
      {
        method: 'GET'
      }
    ).then(this.processResponse);
  },

  recordPromoImpresion({id, description}) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    const body = JSON.stringify({ description });
    return fetch(
      `${baseUrl}/promo/${id}/impression`,
      {
        method: 'PUT',
        body,
        headers
      }
    ).then(this.processResponse);
  },

  dismissPromo(id) {
    const headers = this.getPostJsonHeaders(this.getTokenHeaders());
    return fetch(
      `${baseUrl}/promo/${id}/dismiss`,
      {
        method: 'PUT',
        headers
      }
    ).then(this.processResponse);
  }
};
api.processResponse = api.processResponse.bind(api);

export default api;
