import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';

import { breakpoints, mobileNavHeight, getColor } from '../css/primitives';

const styles = StyleSheet.create({
  footer: {
    gridRowStart: '3',
    gridColumnStart: '1',
    gridColumnEnd: '19',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: getColor('white'),
    padding: '18px 0',
    borderTop: `1px solid ${getColor('black', 0.25)}`,

    [`@media (max-width: ${breakpoints.small})`]: {
      marginBottom: `${mobileNavHeight}px`
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.small})`]: {
      flexDirection: 'column'
    }
  },
  footerLink: {
    marginLeft: '12px',
    color: getColor('black', 0.9),
    [`@media (max-width: ${breakpoints.small})`]: {
      marginTop: '12px'
    }
  }
});

class Footer extends Component {
  render() {
    const { styles: s } = this.props;
    return (
      <footer className={css(styles.footer, s)}>
        <div className={css(styles.content)}>
          Caneta &copy; 2020
          <Link to="/privacy" className={css(styles.footerLink)}>
            Privacy
          </Link>
          <Link to="/terms" className={css(styles.footerLink)}>
            Terms
          </Link>
          <Link to="/help" className={css(styles.footerLink)}>
            Help!
          </Link>
          <a
            className={css(styles.footerLink)}
            href="mailto:feedback@caneta.co"
          >
            Feedback
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
