import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/index';

let middleware = [thunkMiddleware];
const { search } = window.location;
const isDebug = search.includes && search.includes('debug');
if (isDebug || process.env.NODE_ENV !== 'production') {
  const createLogger = require('redux-logger');
  const loggerMiddleware = createLogger();
  middleware = [ ...middleware, loggerMiddleware ];
}


export default preloadedState => {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middleware)
  );
};
