import { StyleSheet } from 'aphrodite';

const colors = {
  'white': {
    'r': 255,
    'g': 255,
    'b': 255
  },
  'gray': {
    '1': {
      'r': 250,
      'g': 250,
      'b': 250
    },
    '2': {
      'r': 245,
      'g': 245,
      'b': 245
    },
    '3': {
      'r': 238,
      'g': 238,
      'b': 238
    },
    '4': {
      'r': 224,
      'g': 224,
      'b': 224
    },
    '5': {
      'r': 189,
      'g': 189,
      'b': 189
    },
    '6': {
      'r': 158,
      'g': 158,
      'b': 158
    },
    '7': {
      'r': 117,
      'g': 117,
      'b': 117
    },
    '8': {
      'r': 97,
      'g': 97,
      'b': 97
    },
    '9': {
      'r': 66,
      'g': 66,
      'b': 66
    },
    '10': {
      'r': 33,
      'g': 33,
      'b': 33
    }
  },
  'black': {
    'r': 0,
    'g': 0,
    'b': 0
  },
  'red': {
    '1': {
      'r': 255,
      'g': 235,
      'b': 238
    },
    '2': {
      'r': 255,
      'g': 205,
      'b': 210
    },
    '3': {
      'r': 239,
      'g': 154,
      'b': 154
    },
    '4': {
      'r': 229,
      'g': 115,
      'b': 115
    },
    '5': {
      'r': 239,
      'g': 83,
      'b': 80
    },
    '6': {
      'r': 244,
      'g': 67,
      'b': 54
    },
    '7': {
      'r': 229,
      'g': 57,
      'b': 53
    },
    '8': {
      'r': 211,
      'g': 47,
      'b': 47
    },
    '9': {
      'r': 198,
      'g': 40,
      'b': 40
    },
    '10': {
      'r': 183,
      'g': 28,
      'b': 28
    }
  },
  'orange': {
    '1': {
      'r': 202,
      'g': 141,
      'b': 29
    },
    '2': {
      'r': 248,
      'g': 200,
      'b': 112
    },
    '3': {
      'r': 222,
      'g': 167,
      'b': 66
    },
    '4': {
      'r': 169,
      'g': 114,
      'b': 10
    },
    '5': {
      'r': 137,
      'g': 89,
      'b': 0
    }
  },
  'yellow': {
    '1': {
      'r': 255,
      'g': 253,
      'b': 231
    },
    '2': {
      'r': 255,
      'g': 249,
      'b': 196
    },
    '3': {
      'r': 255,
      'g': 245,
      'b': 157
    },
    '4': {
      'r': 255,
      'g': 241,
      'b': 118
    },
    '5': {
      'r': 255,
      'g': 238,
      'b': 88
    },
    '6': {
      'r': 255,
      'g': 235,
      'b': 59
    },
    '7': {
      'r': 253,
      'g': 216,
      'b': 53
    },
    '8': {
      'r': 251,
      'g': 192,
      'b': 45
    },
    '9': {
      'r': 249,
      'g': 168,
      'b': 37
    },
    '10': {
      'r': 245,
      'g': 127,
      'b': 23
    }
  },
  'green': {
    '1': {
      'r': 85,
      'g': 169,
      'b': 133
    },
    '2': {
      'r': 66,
      'g': 159,
      'b': 119
    },
    '3': {
      'r': 48,
      'g': 150,
      'b': 106
    },
    '4': {
      'r': 44,
      'g': 137,
      'b': 97
    },
    '5': {
      'r': 40,
      'g': 123,
      'b': 87
    }
  },
  'blue': {
    '1': {
      'r': 227,
      'g': 242,
      'b': 253
    },
    '2': {
      'r': 187,
      'g': 222,
      'b': 251
    },
    '3': {
      'r': 144,
      'g': 202,
      'b': 249
    },
    '4': {
      'r': 100,
      'g': 181,
      'b': 246
    },
    '5': {
      'r': 66,
      'g': 165,
      'b': 245
    },
    '6': {
      'r': 33,
      'g': 150,
      'b': 243
    },
    '7': {
      'r': 30,
      'g': 136,
      'b': 229
    },
    '8': {
      'r': 25,
      'g': 118,
      'b': 210
    },
    '9': {
      'r': 21,
      'g': 101,
      'b': 192
    },
    '10': {
      'r': 13,
      'g': 71,
      'b': 161
    }
  },
  'purple': {
    '1': {
      'r': 243,
      'g': 229,
      'b': 245
    },
    '2': {
      'r': 225,
      'g': 190,
      'b': 231
    },
    '3': {
      'r': 206,
      'g': 147,
      'b': 216
    },
    '4': {
      'r': 186,
      'g': 104,
      'b': 200
    },
    '5': {
      'r': 171,
      'g': 71,
      'b': 188
    },
    '6': {
      'r': 156,
      'g': 39,
      'b': 176
    },
    '7': {
      'r': 142,
      'g': 36,
      'b': 170
    },
    '8': {
      'r': 123,
      'g': 31,
      'b': 162
    },
    '9': {
      'r': 106,
      'g': 27,
      'b': 154
    },
    '10': {
      'r': 74,
      'g': 20,
      'b': 140
    }
  },
  'strava': {
    'r': 201,
    'g': 61,
    'b': 2
  },
  'instagram': {
    'r': 131,
    'g': 58,
    'b': 180
  }
};

colors.primary = colors.blue[4];
colors.accent = colors.orange[3];

const colorIdxRegex = /([a-z]+)(\d+)/;

export const getColor = (color, opacity=1) => {
  let retVal = '';
  const colorMatch = color.match(colorIdxRegex);
  const rgb = colorMatch ? colors[colorMatch[1]][colorMatch[2]] : colors[color];
  if (opacity === 1) {
    retVal = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  } else {
    retVal = `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
  }
  return retVal;
};

export const breakpoints = {
  large: '960px',
  medium: '800px',
  small: '600px'
};

export const elevations = [
  {blur: ['1px 3px', '1px 2px'], opacity: [0.12, 0.24]},
  {blur: ['3px 6px', '3px 6px'], opacity: [0.16, 0.23]},
  {blur: ['10px 20px', '6px 6px'], opacity: [0.19, 0.23]},
  {blur: ['14px 28px', '10px 10px'], opacity: [0.25, 0.22]},
  {blur: ['19px 38px', '15px 12px'], opacity: [0.3, 0.22]}
].map(({blur, opacity}) => ({
  boxShadow: `0 ${blur[0]} rgba(0,0,0,${opacity[0]}), 0 ${blur[1]} rgba(0,0,0,${opacity[1]})`
}));

export const fontFamilies = {
  serif: "'Lora', 'Apple Garamond', 'Baskerville', 'Times New Roman', 'Droid Serif', 'Times','Source Serif Pro', serif;",
  sans: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue',sans-serif"
};

export const mobileNavHeight = 44;
export const headerHeight = 50;

export const a11yText = {
  // Suggested method from
  // http://webaim.org/techniques/css/invisiblecontent/
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden'
};

export const responsiveWidths = {
  width: '960px',
  [`@media (max-width: ${breakpoints.large})`]: {
    width: '800px',
  },

  [`@media (max-width: ${breakpoints.medium})`]: {
    width: '640px'
  },
  [`@media (max-width: ${breakpoints.small})`]: {
    width: '100vw',
    maxWidth: '100%'
  }
};

export const gridColumns = {
  gridTemplateColumns: '1fr repeat(16, 48px) 1fr',
  gridColumnGap: '12px',
  [`@media (max-width: ${breakpoints.large})`]: {
    gridTemplateColumns: '1fr repeat(16, 38px) 1fr',
  },

  [`@media (max-width: ${breakpoints.medium})`]: {
    gridTemplateColumns: '1fr repeat(16, 25.5px) 1fr',
  },
  [`@media (max-width: ${breakpoints.small})`]: {
    gridTemplateColumns: '0 repeat(16, 1fr) 0',
  }
};

export const entryGrid = {
  display: 'grid',
  gridTemplateColumns: '12px 1fr 12px',
};

export const primitives = StyleSheet.create({
  link: {
    color: colors.primary,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  a11yText
});
