import * as actions from '../actionTypes';
import { TOKEN_LOCAL_STORAGE_KEY } from '../api';

const auth = (
  state = {
    user: {
      promos: {},
      isSubscriptionActive: true,
      isFetched: false
    },
    isFetching: false,
    loginError: null,
    isAuthenticated: window.localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) ? true : false,
    isEmailConfirmed: false
  },
  action
) => {
  switch (action.type) {
  case actions.LOGIN_REQUEST:
    return {
      ...state,
      isFetching: true
    };
  case actions.LOGIN_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isAuthenticated: true,
      loginError: null,
      user: {
        ...state.user,
        ...action.user,
        isFetched: true
      }
    };
  case actions.LOGIN_FAILURE:
    return {
      ...state,
      isFetching: false,
      loginError: action.err,
      isAuthenticated: false
    };
  case actions.LOGOUT_REQUEST:
    return {
      ...state,
      isFetching: true,
      isAuthenticated: false
    };
  case actions.LOGOUT_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isAuthenticated: false,
      user: null
    };
  case actions.REQUEST_USER_DETAILS:
    return {
      ...state,
      isFetching: true
    };
  case actions.RECEIVE_USER_DETAILS:
    const user = {...action.user};
    delete user.isEmailConfirmed;
    return {
      ...state,
      isFetching: false,
      user: {
        ...state.user,
        ...user,
        promos: {
          ...state.user.promos,
          ...user.promos
        },
        isFetched: true
      },
      isEmailConfirmed: action.user.isEmailConfirmed
    };
  case actions.CONFIRM_EMAIL_CONFIRMED:
    return {
      ...state,
      isEmailConfirmed: true
    };
  case actions.CONFIRM_INSTAGRAM_CONNECT:
    return {
      ...state,
      user: {
        ...state.user,
        instagramAccessToken: action.user.instagramAccessToken
      }
    };
  case actions.CONFIRM_INSTAGRAM_ACCESS:
    return {
      ...state,
      user: {
        ...state.user,
        instagramAccessToken: action.hasAccess ? state.user.instagramAccessToken : null
      }
    };
  case actions.CONFIRM_PUSH_SUBSCRIPTION:
    return {
      ...state,
      user: {
        ...state.user,
        webPushSubscription: action.subscription.endpoint
      }
    };
  case actions.CONFIRM_PUSH_UNSUBSCIBED:
    return {
      ...state,
      user: {
        ...state.user,
        webPushSubscription: null
      }
    };
  case actions.CONFIRM_SET_REMINDER_TIME:
    return {
      ...state,
      user: {
        ...state.user,
        reminderTime: action.time
      }
    };
  case actions.CONFIRM_CARD_ADDED:
    return {
      ...state,
      user: {
        ...state.user,
        subscription: {
          ...state.user.subscription,
          paymentSources: [
            ...state.user.subscription.paymentSources,
            action.card
          ]
        }
      }
    };
  case actions.CONFIRM_CARD_REMOVED:
    return {
      ...state,
      user: {
        ...state.user,
        subscription: {
          ...state.user.subscription,
          paymentSources: state.user.subscription.paymentSources.filter(
            source => source.id !== action.cardId
          )
        }
      }
    };
  case actions.CONFIRM_USER_PLAN_UPDATED:
    return {
      ...state,
      user: {
        ...state.user,
        isSubscriptionActive: true,
        subscription: {
          ...state.user.subscription,
          plan: action.plan,
          cancel_at_period_end: false
        }
      }
    };
  case actions.CONFIRM_SUBSCRIPTION_CANCELED:
    return {
      ...state,
      user: {
        ...state.user,
        isSubscriptionActive: false,
        subscription: {
          ...state.user.subscription,
          status: action.subscription.status,
          cancel_at_period_end: action.subscription.cancel_at_period_end
        }
      }
    };
  case actions.CONFIRM_ENTRY_CREATED:
    return {
      ...state,
      user: {
        ...state.user,
        hasEntries: true
      }
    }
  case actions.CONFIRM_PROMO_DISMISSED:
  case actions.CONFIRM_PROMO_IMPRESSION:
    return {
      ...state,
      user: {
        ...state.user,
        promos: {
          ...state.user.promos,
          [action.promo.stringId]: action.promo
        }
      }
    };
  default:
    return state;
  }
};

export default auth;
