import { ApolloClient } from 'apollo-client';
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import localforage from 'localforage';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { createBrowserHistory } from 'history';

import AuthRoute from './lib/AuthRoute';
import Footer from './Footer';
import Header from './Header';
import Loading from './lib/Loading';
import NewVersionPrompt from './NewVersionPrompt';
import ScrollToTop from './ScrollToTop';

import { StyleSheet, css } from 'aphrodite';
import introspectionQueryResultData from '../fragmentTypes.json';
import { baseUrl, TOKEN_LOCAL_STORAGE_KEY } from '../api';
import {
  breakpoints,
  fontFamilies,
  getColor,
  gridColumns,
  headerHeight
} from '../css/primitives';
import HomescreenPrompt from './HomescreenPrompt';

const history = createBrowserHistory();

const BeginPasswordReset = Loadable({
  loader: () => import('../containers/BeginPasswordReset'),
  loading: Loading
});
const ConfirmEmail = Loadable({
  loader: () => import('../containers/ConfirmEmail'),
  loading: Loading
});
const CreateEntry = Loadable({
  loader: () => import('../containers/CreateEntry'),
  loading: Loading
});
const Entries = Loadable({
  loader: () => import('./Entries'),
  loading: Loading
});
const FAQs = Loadable({
  loader: () => import('./FAQs'),
  loading: Loading
});
const Gift = Loadable({
  loader: () => import('../containers/Gift'),
  loading: Loading
});
const Help = Loadable({
  loader: () => import('./Help'),
  loading: Loading
});
const GooglePhotosTokenExchange = Loadable({
  loader: () => import('../containers/GooglePhotosTokenExchange'),
  loading: Loading
});
const Home = Loadable({
  loader: () => import('../containers/Home'),
  loading: Loading
});
const Import = Loadable({
  loader: () => import('../containers/Import'),
  loading: Loading
});
const InstagramTokenExchange = Loadable({
  loader: () => import('../containers/InstagramTokenExchange'),
  loading: Loading
});
const Login = Loadable({
  loader: () => import('../containers/Login'),
  loading: Loading
});
const Logout = Loadable({
  loader: () => import('../containers/Logout'),
  loading: Loading
});
const Nav = Loadable({
  loader: () => import('../containers/Nav'),
  loading: Loading
});
/*
const NoMatch = Loadable({
  loader: () => import('./NoMatch'),
  loading: Loading
});
*/
const OnThisDay = Loadable({
  loader: () => import('../containers/OnThisDay'),
  loading: Loading
});
const PrivacyPolicy = Loadable({
  loader: () => import('./Privacy'),
  loading: Loading
});
const ResetPassword = Loadable({
  loader: () => import('../containers/ResetPassword'),
  loading: Loading
});
const Settings = Loadable({
  loader: () => import('./settings/Settings'),
  loading: Loading
});
const Signup = Loadable({
  loader: () => import('../containers/Signup'),
  loading: Loading
});
const StravaTokenExchange = Loadable({
  loader: () => import('../containers/StravaTokenExchange'),
  loading: Loading
});
const SubscriptionExpired = Loadable({
  loader: () => import('../containers/SubscriptionExpired'),
  loading: Loading
});
const TermsOfService = Loadable({
  loader: () => import('./TermsOfService'),
  loading: Loading
});
const TopicEntries = Loadable({
  loader: () => import('../containers/TopicEntries'),
  loading: Loading
});
const Tour = Loadable({
  loader: () => import('./tour/Tour'),
  loading: Loading
});
const Unsubscribe = Loadable({
  loader: () => import('../containers/Unsubscribe'),
  loading: Loading
});

const styles = StyleSheet.create({
  root: {
    fontFamily: fontFamilies.sans,
    position: 'relative',
    minHeight: '100%',
    display: 'grid',
    ...gridColumns,
    gridTemplateRows: `${headerHeight}px auto auto`,
    justifyItems: 'center',
    backgroundColor: getColor('gray1')
  },
  body: {
    gridRowStart: '2',
    gridRowEnd: '3',
    gridColumnStart: '2',
    gridColumnEnd: '17',
    width: '100%',
    padding: `24px 0 24px 0`,
    [`@media (max-width: ${breakpoints.small})`]: {
      width: '100vw',
      maxWidth: '100%',
      padding: `0`,
      gridColumnStart: '1',
      gridColumnEnd: '19',
      gridRowStart: '1',
      gridRowEnd: '3',
      marginTop: '0',
      boxSizing: 'border-box'
    }
  }
});

class App extends Component {
  state = {
    deferredHomescreenPrompt: null,
    client: null,
    loaded: null
  };

  constructor(props) {
    super(props);
    if (props.auth.isAuthenticated && !props.auth.user.isFetched) {
      props.fetchUserDetails();
    }
    props.fetchLatestVersion();
    this.setupHomescreenListener();
  }

  async componentDidMount() {
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData
    });
    const cache = new InMemoryCache({ fragmentMatcher });
    const link = new HttpLink({
      uri: `${baseUrl}/graphql`
    });

    const middlewareLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) || null
        }
      });
      return forward(operation);
    });
    const client = new ApolloClient({
      cache,
      link: middlewareLink.concat(link)
    });

    try {
      await persistCache({
        cache,
        storage: localforage
      });
    } catch (error) {
      console.error('Error restoring Apollo cache', error);
    }

    this.setState({
      client,
      loaded: true
    });
  }

  setupHomescreenListener() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.setState({
        deferredHomescreenPrompt: e
      });
    });
  }

  render() {
    const { client, loaded } = this.state;
    const isAuthenticated = this.props.auth.isAuthenticated;
    const appClasses = `App ${css(styles.root)}`;
    const { deferredHomescreenPrompt } = this.state;
    if (!loaded) {
      return <Loading />;
    }
    return (
      <ApolloProvider client={client}>
        <DocumentTitle title="Caneta">
          <Router history={history}>
            <ScrollToTop>
              <div className={appClasses}>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <AuthRoute
                    isAuthenticated={isAuthenticated}
                    path="/tour"
                    component={Tour}
                  />
                  <Route
                    render={() => [
                      <Header key="header">
                        <Nav />
                      </Header>,
                      <NewVersionPrompt
                        key="version-prompt"
                        latestVersion={this.props.meta.latestVersion}
                      />,
                      <div key="body" className={css(styles.body)}>
                        <Switch>
                          <Route path="/login" component={Login} />
                          <Route path="/logout" component={Logout} />
                          <Route path="/signup" component={Signup} />
                          <Route path="/help" component={Help} />
                          <Route
                            path="/unsubscribe/:nonce"
                            component={Unsubscribe}
                          />
                          <Route path="/gift" component={Gift} />
                          <Route path="/faqs" component={FAQs} />
                          <AuthRoute
                            path="/settings"
                            isAuthenticated={isAuthenticated}
                            component={Settings}
                          />
                          <AuthRoute
                            path="/entries"
                            isAuthenticated={isAuthenticated}
                            exact
                            component={Entries}
                          />
                          <Route
                            path="/privacy"
                            exact
                            component={PrivacyPolicy}
                          />
                          <Route
                            path="/terms"
                            exact
                            component={TermsOfService}
                          />
                          <AuthRoute
                            path="/write"
                            exact
                            isAuthenticated={isAuthenticated}
                            component={CreateEntry}
                          />
                          <AuthRoute
                            path="/on-this-day/:month/:day"
                            isAuthenticated={isAuthenticated}
                            component={OnThisDay}
                          />
                          <AuthRoute
                            path="/import"
                            isAuthenticated={isAuthenticated}
                            component={Import}
                          />
                          <AuthRoute
                            path="/topic/:id"
                            isAuthenticated={isAuthenticated}
                            component={TopicEntries}
                          />
                          <AuthRoute
                            path="/strava-token-exchange"
                            isAuthenticated={isAuthenticated}
                            component={StravaTokenExchange}
                          />
                          <AuthRoute
                            path="/instagram-token-exchange"
                            isAuthenticated={isAuthenticated}
                            component={InstagramTokenExchange}
                          />
                          <AuthRoute
                            path="/google-photos-token-exchange"
                            isAuthenticated={isAuthenticated}
                            component={GooglePhotosTokenExchange}
                          />
                          <Route
                            path="/confirm-email/:confirmationCode"
                            component={ConfirmEmail}
                          />
                          <AuthRoute
                            path="/subscription-expired"
                            isAuthenticated={isAuthenticated}
                            component={SubscriptionExpired}
                          />
                          <Route
                            path="/reset-password/:resetCode/:nonce"
                            component={ResetPassword}
                          />
                          <Route
                            path="/begin-password-reset/"
                            component={BeginPasswordReset}
                          />
                        </Switch>
                      </div>,
                      <Footer key="footer" />,
                      deferredHomescreenPrompt ? (
                        <HomescreenPrompt
                          deferredEvt={deferredHomescreenPrompt}
                        />
                      ) : null
                    ]}
                  />
                </Switch>
              </div>
            </ScrollToTop>
          </Router>
        </DocumentTitle>
      </ApolloProvider>
    );
  }
}

App.propTypes = {
  fetchUserDetails: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.object
  }).isRequired
};

export default App;
