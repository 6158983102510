import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

class AuthRoute extends Component {
  render() {
    const Comp = this.props.component;
    const routeProps = {...this.props};
    delete routeProps.component;
    return (
      <Route {...routeProps} render={props => (
        this.props.isAuthenticated ?
        (<Comp {...props}/>) :
        (<Redirect to={{
          pathname: '/',
          state: {from: props.location}
        }} />)
      )} />
    );
  }
}

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default AuthRoute;
