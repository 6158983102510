import * as actions from '../actionTypes';

const entry = (state = {}, action) => {
  let entryId;
  switch (action.type) {
  case actions.EDIT_ENTRY:
    if (state.id !== action.id) {
      return state;
    }
    return {
      ...state,
      isEditing: true
    };
  case actions.CANCEL_ENTRY_EDITS:
    if (state.id !== action.id) {
      return state;
    }
    return {
      ...state,
      isEditing: false
    };
  case actions.UPDATE_ENTRY:
    if (state.id !== action.values.id) {
      return state;
    }
    return {
      ...state,
      ...action.values,
      // Don't try to update topics until the sync is done
      topics: state.topics,
      isEditing: false,
      isDirty: true
    };
  case actions.CONFIRM_ENTRY_SYNC:
    entryId = parseInt(action.entry.id, 10);
    if (state.id !== entryId) {
      return state;
    }

    return {
      ...state,
      ...action.entry,
      id: entryId,
      date: new Date(action.entry.date),
      isEditing: false,
      isDirty: false
    };
  case actions.CONFIRM_ENTRY_CREATED:
    if (state.tmpId !== action.tmpId) {
      return state;
    }

    return {
      ...state,
      id: action.entry.id,
      images: action.entry.images.length ? action.entry.images : state.images,
      topics: action.entry.topics,
      tmpId: null
    };
  case actions.CONFIRM_IMAGE_UPLOADED:
    entryId = parseInt(action.entryId, 10);
    if (entryId !== state.id && entryId !== state.tmpId) {
      return state;
    }

    return {
      ...state,
      images: state.images.map((image) => {
        if (image.id === action.image.tmpId) {
          return action.image;
        }
        return image;
      })
    }
  default:
    return state;
  }
};

export default entry;
