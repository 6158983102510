import entry from './entry';
import * as actions from '../actionTypes';
import { logos } from '../utils/appLogos';
import { DRAFT_LOCAL_STORAGE_KEY } from '../api';

const sortEntriesByDate = (a, b) => {
  return a.date < b.date ? 1 :
         a.date === b.date ? 0 :
         -1;
};

const entryMap = entry => {
  entry.date = new Date(entry.date);
  const intId = parseInt(entry.id, 10);
  if (intId.toString() === entry.id) {
    entry.id = intId;
  }
  return entry;
};

const removeDuplicateEntries = entries => {
  // Remove duplicates (fetched entries that are already in the store)
  const entriesMap = entries.reduce((acc, entry) => {
    if (!acc[entry.id]) {
      acc[entry.id] = entry;
    } else {
      // merge duplicates
      acc[entry.id].images = acc[entry.id].images.length >= entry.images.length ? acc[entry.id].images : entry.images;
    }
    return acc;
  }, {});
  return Object.keys(entriesMap).map(entryId => entriesMap[entryId]);
};

const addLogoSrc = entry => ({
  ...entry,
  logoSrc: logos[entry.type]
});

const entries = (state = {
  isFetching: false,
  entries: [],
  draft: window.localStorage.getItem(DRAFT_LOCAL_STORAGE_KEY) || ''
}, action) => {
  switch (action.type) {
  case actions.REQUEST_LATEST_ENTRIES:
  case actions.REQUEST_ON_THIS_DAY_ENTRIES:
    return {
      ...state,
      isFetching: true
    };
  case actions.RECEIVE_ON_THIS_DAY_ENTRIES:
    const yearEntries = action.entriesData.entries.map(entryMap)
                    .concat(state.entries);
    const lastWeekEntries = action.entriesData.lastWeekEntries.map(entryMap);
    const lastMonthEntries = action.entriesData.lastMonthEntries.map(entryMap);
    return {
      ...state,
      isFetching: false,
      pages: action.entriesData.pages,
      count: action.entriesData.count,
      page: action.entriesData.page,
      entries: removeDuplicateEntries(yearEntries.concat(lastWeekEntries, lastMonthEntries))
      .map(addLogoSrc)
      .sort(sortEntriesByDate)
    };
  case actions.RECEIVE_ENTRIES:
    const entries = action.entriesData.entries.map(entryMap)
                    .concat(state.entries);
    return {
      ...state,
      isFetching: false,
      pages: action.entriesData.pages,
      count: action.entriesData.count,
      page: action.entriesData.page,
      entries: removeDuplicateEntries(entries)
      .map(addLogoSrc)
      .sort(sortEntriesByDate)
    };
  case actions.RECEIVE_DAILY_REVIEW:
    return {
      ...state,
      entries: action.data.entries.map(entryMap).map(addLogoSrc),
      suggestedEntries: action.data.suggestedEntries
    };
  case actions.UPDATE_SUGGESTED_ENTRY:
    action.entry = entryMap(action.entry);
    return {
      ...state,
      suggestedEntries: {
        ...state.suggestedEntries,
        [action.entry.type]: state.suggestedEntries[action.entry.type]
        .map(entry => {
          return `${entry.id}` === `${action.entry.id}` ?
          { ...entry, ...action.entry } : entry;
        })
      }
    };
  case actions.CLEAR_ENTRIES_CACHE:
    const thisYear = (new Date()).getFullYear();
    return {
      ...state,
      entries: state.entries.filter(entry => entry.date.getFullYear() === thisYear)
    };
  case actions.REQUEST_TOPIC_ENTRIES:
    return {
      ...state,
      isFetching: true
    };
  case actions.RECEIVE_TOPIC_ENTRIES:
    const topicEntries = action.entries.map(entry => {
      entry.date = new Date(entry.date);
      return entry;
    }).concat(state.entries);
    return {
      ...state,
      isFetching: false,
      topic: action.topic,
      entries: removeDuplicateEntries(topicEntries).sort(sortEntriesByDate)
    };
  case actions.CREATE_NEW_ENTRY:
    return {
      ...state,
      entries: [action.values].concat(state.entries).sort(sortEntriesByDate)
    };
  case actions.DELETE_ENTRY:
    return {
      ...state,
      entries: state.entries.filter(e => e.id !== action.id)
    };
  case actions.UPLOAD_ENTRIES:
    return {
      ...state,
      isUploading: true
    };
  case actions.CONFIRM_ENTRIES_UPLOADED:
    return {
      ...state,
      isUploading: false
    };
  case actions.LOGOUT_SUCCESS:
    return {
      ...state,
      draft: '',
      entries: []
    };
  case actions.SAVE_DRAFT:
    return {
      ...state,
      draft: action.draft
    };
  case actions.CONFIRM_ENTRY_CREATED:
    return {
      ...state,
      draft: '',
      entries: [
        ...state.entries,
        entryMap(action.entry.saveEntry)
      ].sort(sortEntriesByDate)
    };
  case actions.EDIT_ENTRY:
  case actions.CANCEL_ENTRY_EDITS:
  case actions.UPDATE_ENTRY:
  case actions.SYNC_DIRTY_ENTRY:
  case actions.CONFIRM_ENTRY_SYNC:
  case actions.CONFIRM_IMAGE_UPLOADED:
    return {
      ...state,
      entries: state.entries.map(e => entry(e, action))
    };
  default:
    return state;
  }
};

export default entries;
