import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class ScrollToTop extends Component {
  location = '';

  render() {
    return (<Route render={(props) => {
      if (props.location !== this.location) {
        this.location = props.location;
        window.scrollTo(0, 0);
      }
      return this.props.children;
    }} />);
  }
}

export default ScrollToTop;
