import * as actions from '../actionTypes';


const meta = (
  state = {
    latestVersion: null,
  },
  action
) => {
  switch (action.type) {
  case actions.RECEIVE_LATEST_VERSION:
    return {
      ...state,
      latestVersion: action.version
    };
  default:
    return state;
  }
};

export default meta;
