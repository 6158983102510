import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';

import {
  breakpoints,
  getColor,
  gridColumns,
  fontFamilies
} from '../css/primitives';

const styles = StyleSheet.create({
  header: {
    display: 'grid',
    ...gridColumns,
    gridRowStart: '1',
    gridRowEnd: '2',
    gridColumnStart: '1',
    gridColumnEnd: '19',
    width: '100vw',
    borderBottom: `1px solid ${getColor('primary')}`,
    backgroundColor: getColor('white'),
    [`@media (max-width: ${breakpoints.small})`]: {
      height: '0',
    }
  },
  logo: {
    gridColumnStart: '2',
    gridColumnEnd: '4',
    margin: '8px 24px 0 24px',
    [`@media (max-width: ${breakpoints.small})`]: {
      visibility: 'hidden'
    }
  },
  logoText: {
    display: 'flex',
    alignItems: 'center',
    color: getColor('blue5'),
    textDecoration: 'none',
    fontFamily: fontFamilies.serif,
  },
  caneta: {
    fontSize: '28px',
    marginLeft: '8px',
    [`@media (max-width: ${breakpoints.medium})`]: {
      fontSize: '18px'
    },
  }
});

const Header = ({ children }) => (
  <header className={css(styles.header)}>
    <h1 className={css(styles.logo)}>
      <Link to="/" className={css(styles.logoText)}>
        <img src="/caneta-logo.png" alt="Caneta - the nearly automated journal" />
        <span className={css(styles.caneta)}>Caneta</span>
      </Link>
    </h1>
    {children}
  </header>
);

export default Header;

