import { combineReducers } from 'redux';

import auth from './auth';
import entries from './entries';
import topics from './topics';
import connectedApps from './connectedApps';
import meta from './meta';

const rootReducer = combineReducers({
  auth,
  entries,
  topics,
  connectedApps,
  meta
});

export default rootReducer;
