import * as actions from './actionTypes';
import api, { ON_THIS_DAY } from './api';


export const loginRequest = () => (
  {
    type: actions.LOGIN_REQUEST
  }
);

export const loginSuccess = (user) => (
  {
    type: actions.LOGIN_SUCCESS,
    user
  }
);

export const loginFailure = (err) => (
  {
    type: actions.LOGIN_FAILURE,
    err
  }
);

export const logoutRequet = () => (
  {
    type: actions.LOGOUT_REQUEST
  }
);

export const logoutSuccess = () => (
  {
    type: actions.LOGOUT_SUCCESS
  }
);

export const requestUserDetails = () => (
  {
    type: actions.REQUEST_USER_DETAILS
  }
);

export const receiveUserDetails = (user) => (
  {
    type: actions.RECEIVE_USER_DETAILS,
    user
  }
);

export const requestLatestEntries = page => (
  {
    type: actions.REQUEST_LATEST_ENTRIES,
    page
  }
);

export const receiveEntries = entriesData => (
  {
    type: actions.RECEIVE_ENTRIES,
    entriesData
  }
);

export const receiveOnThisDayEntries = entriesData => (
  {
    type: actions.RECEIVE_ON_THIS_DAY_ENTRIES,
    entriesData
  }
);

export const clearEntriesCache = () => (
  {
    type: actions.CLEAR_ENTRIES_CACHE
  }
);

export const requestOnThisDayEntries = (month, day, page) => (
  {
    type: actions.REQUEST_ON_THIS_DAY_ENTRIES,
    month,
    day,
    page
  }
);

export const requestTopicEntries = (topic, page) => (
  {
    type: actions.REQUEST_TOPIC_ENTRIES,
    topic,
    page
  }
);

export const receiveTopicEntries = (topic, entries) => (
  {
    type: actions.RECEIVE_TOPIC_ENTRIES,
    topic,
    entries
  }
);

export const receiveTopicsForQuery = (q, topics) => (
  {
    type: actions.RECEIVE_TOPICS_FOR_QUERY,
    query: q,
    topics
  }
);

export const createTopic = (name) => (
  {
    type: actions.CREATE_TOPIC,
    name
  }
);

export const confirmTopicCreated = (topic) => (
  {
    type: actions.CONFIRM_TOPIC_CREATED,
    topic
  }
);

export const createNewEntry = values => (
  {
    type: actions.CREATE_NEW_ENTRY,
    values
  }
);

export const uploadEntries = file => (
  {
    type: actions.UPLOAD_ENTRIES,
    file
  }
);

export const confirmEntriesUploaded = count => (
  {
    type: actions.CONFIRM_ENTRIES_UPLOADED,
    count
  }
);

export const confirmEntryCreated = (entry, tmpId) => (
  {
    type: actions.CONFIRM_ENTRY_CREATED,
    entry,
    tmpId
  }
);

export const confirmImageUploaded = image => (
  {
    type: actions.CONFIRM_IMAGE_UPLOADED,
    image,
    entryId: image.EntryId
  }
);

export const confirmEntryImagesDeleted = (id) => (
  {
    type: actions.CONFIRM_ENTRY_IMAGES_DELETED,
    id
  }
);

export const draftSaved = (draft) => (
  {
    type: actions.SAVE_DRAFT,
    draft
  }
);

export const syncDirtyEntry = id => (
  {
    type: actions.SYNC_DIRTY_ENTRY,
    id
  }
);

export const editEntry = id => (
  {
    type: actions.EDIT_ENTRY,
    id
  }
);

export const stopEditing = id => (
  {
    type: actions.CANCEL_ENTRY_EDITS,
    id
  }
);

export const updateEntry = values => (
  {
    type: actions.UPDATE_ENTRY,
    values
  }
);

export const confirmEntrySync = entry => (
  {
    type: actions.CONFIRM_ENTRY_SYNC,
    entry
  }
);

export const deleteEntry = id => (
  {
    type: actions.DELETE_ENTRY,
    id
  }
);

export const confirmUserRegistered = userDetails => (
  {
    type: actions.CONFIRM_USER_REGISTERED,
    userDetails
  }
);

export const confirmCardAdded = card => (
  {
    type: actions.CONFIRM_CARD_ADDED,
    card
  }
);

export const confirmCardRemoved = cardId => (
  {
    type: actions.CONFIRM_CARD_REMOVED,
    cardId
  }
);

export const confirmUserPlanUpdated = plan => (
  {
    type: actions.CONFIRM_USER_PLAN_UPDATED,
    plan
  }
);

export const confirmSubscriptionCanceled = subscription => (
  {
    type: actions.CONFIRM_SUBSCRIPTION_CANCELED,
    subscription
  }
);

export const confirmStravaConnect = () => (
  {
    type: actions.CONFIRM_STRAVA_CONNECT
  }
);

export const confirmInstagramConnect = (user) => (
  {
    type: actions.CONFIRM_INSTAGRAM_CONNECT,
    user
  }
);

export const confirmFacebookConnect = user => (
  {
    type: actions.CONFIRM_FACEBOOK_CONNECT,
    user
  }
);

export const confirmPushSubscription = (subscription) => (
  {
    type: actions.CONFIRM_PUSH_SUBSCRIPTION,
    subscription
  }
);


export const confirmPushUnsubscribed = () => (
  {
    type: actions.CONFIRM_PUSH_UNSUBSCIBED
  }
);

export const confirmSetReminderTime = (time) => (
  {
    type: actions.CONFIRM_SET_REMINDER_TIME,
    time
  }
);

export const emailConfirmed = () => (
  {
    type: actions.CONFIRM_EMAIL_CONFIRMED
  }
);

export const requireLogin = () => (
  {
    type: actions.LOGOUT_SUCCESS
  }
);

export const receiveLatestVersion = (version) => (
  {
    type: actions.RECEIVE_LATEST_VERSION,
    version
  }
);

export const confirmPromoDismiss = (promo) => (
  {
    type: actions.CONFIRM_PROMO_DISMISSED,
    promo
  }
);

export const confirmPromoImpression = (promo) => (
  {
    type: actions.CONFIRM_PROMO_IMPRESSION,
    promo
  }
);

//////////////////////////////////////////
//
// ASYNC ACTIONS
//
//////////////////////////////////////////







const validateAPIResponse = (response, dispatch) => {
  if (response && response.requireLogin) {
    dispatch(requireLogin());
    throw new Error('A login is required before continuing');
  }
  return Promise.resolve(response);
};

const noop = () => {};


export const fetchLatestEntries = page => {
  return dispatch => {
    dispatch(requestLatestEntries(page));
    return api.fetchEntries()(page)
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => dispatch(receiveEntries(data)))
    .catch(noop);
  };
};

export const fetchOnThisDayEntries = (month, day, page) => {
  return dispatch => {
    dispatch(requestOnThisDayEntries(month, day, page));
    return api.fetchEntries(ON_THIS_DAY)(month, day, page)
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => dispatch(receiveOnThisDayEntries(data)))
    .catch(noop);
  };
};

export const fetchTopicEntries = (topic, page) => {
  return dispatch => {
    dispatch(requestTopicEntries(topic, page));
    return api.fetchEntries('topic')(topic, page)
    .then(data => validateAPIResponse(data, dispatch))
    .then(res => dispatch(receiveTopicEntries(res.topic, res.entries)))
    .catch(noop);
  };
};

export const syncEntry = (query, variables) => {
  return dispatch => {
    // dispatch(updateEntry(dirtyEntry));
    // dispatch(syncDirtyEntry(dirtyEntry.id));
    return api.graphql(query, variables)
    .then(data => validateAPIResponse(data, dispatch))
    .then(({ updateEntry: entry }) => {
      dispatch(confirmEntrySync(entry));
      return entry;
    })
    .catch(noop);
  };
};

export const syncDeleteEntry = id => {
  return dispatch => {
    return api.deleteEntry(id)
    .then(data => validateAPIResponse(data, dispatch))
    .then(() => dispatch(deleteEntry(id)))
    .catch(noop);
  };
};

export const syncCreateEntry = (query, variables) => {
  const { id: tmpId } = variables.entry;
  return dispatch => {
    // TODO: locally cache entries that fail to sync, to try again later
    return api.graphql(query, variables)
    .then(data => {
      api.saveDraft('');
      return validateAPIResponse(data, dispatch);
    })
    .then(entry => dispatch(confirmEntryCreated(entry, tmpId)))
    .catch(noop);
  };
};

export const cancelEntryEdits = (id, newImgFiles=[]) => {
  return dispatch => {
    dispatch(stopEditing(id));
    return Promise.all(newImgFiles.map(({ id: imageId }) => {
      return api.deleteEntryImage(imageId)
      .then(validateAPIResponse);
    }))
    .then(() => dispatch(stopEditing(id)))
    .catch(noop);
  };
};

export const syncUploadImage = ({ file, id: tmpId }, entryId, onProgress) => {
  return dispatch => {
    return api.upload(file, onProgress)
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => {
      data = {...data, tmpId, entryId};
      dispatch(confirmImageUploaded(data));
      return data;
    })
    .catch(noop);
  };
};

export const deleteEntryImages = (entryId) => {
  return dispatch => {
    return api.deleteEntryImages(entryId)
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => dispatch(confirmEntryImagesDeleted({...data, entryId})))
    .catch(noop);
  };
};

export const deleteEntryImage = (imageId) => {
  return dispatch => {
    return api.deleteEntryImage(imageId)
    .then(data => validateAPIResponse(data, dispatch))
    .catch(noop);
  };
};

export const syncUploadEntries = file => {
  return dispatch => {
    dispatch(uploadEntries(file));
    return api.uploadEntries(file)
    .then(data => validateAPIResponse(data, dispatch))
    .then(res => dispatch(confirmEntriesUploaded(res.entryCount)))
    .catch(noop);
  };
};

export const syncCreateTopic = name => {
  return dispatch => {
    dispatch(createTopic(name));
    return api.createTopic(name)
    .then(data => validateAPIResponse(data, dispatch))
    .then(topic => dispatch(confirmTopicCreated(topic)))
    .catch(noop);
  };
};

export const queryTopics = q => {
  return (dispatch, getState) => {
    const topicsCache = getState().topics.cache;
    if (topicsCache[q]) {
      return new Promise((resolve) => resolve());
    } else {
      return api.queryTopics(q)
      .then(data => validateAPIResponse(data, dispatch))
      .then(topics => dispatch(receiveTopicsForQuery(q, topics)))
      .catch(noop);
    }
  };
};

export const registerUser = userDetails => {
  return dispatch => {
    return api.signup(userDetails).then(({token, user}) => {
      dispatch(confirmUserRegistered(user));
      api.setToken(token);
      dispatch(loginSuccess(user));
    });
  };
};

export const submitLogin = (email, password) => {
  return dispatch => {
    dispatch(loginRequest());
    return api.login(email, password).then(user => {
      dispatch(loginSuccess(user));
    }).catch(err => {
      dispatch(loginFailure(err.message));
    });
  };
};

export const submitLogout = () => {
  return dispatch => {
    dispatch(logoutRequet());
    return api.logout().then(() => {
      dispatch(logoutSuccess());
    });
  };
};

export const fetchUserDetails = () => {
  return dispatch => {
    dispatch(requestUserDetails());
    return api.fetchUserDetails()
    .then(data => validateAPIResponse(data, dispatch))
    .then((user) => dispatch(receiveUserDetails(user)))
    .catch(noop);
  };
};

export const createGiftOrder = details => {
  return dispatch => {
    return api.createGiftOrder(details)
    .then(order => ({order, details}));
  };
};

export const sendGift = (details, order) => {
  return dispatch => {
    return api.sendGift(details, order);
  };
};

export const connectStrava = code => {
  return dispatch => {
    return api.connectStrava(code)
    .then(() => dispatch(confirmStravaConnect()));
  };
};

export const connectInstagram = code => {
  return dispatch => {
    return api.connectInstagram(code)
    .then((data) => dispatch(confirmInstagramConnect(data.user)));
  };
};

export const connectGooglePhotos = code => {
  return api.connectGooglePhotos(code);
};

export const setFacebookAccessToken = authResponse => {
  return dispatch => {
    return api.setFacebookAccessToken(authResponse)
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => dispatch(confirmFacebookConnect(data.user)))
    .catch(noop);
  };
};

export const deleteFacebookAccessToken = accessToken => {
  return dispatch => {
    return api.deleteFacebookAccessToken(accessToken);
  };
};

export const syncRegisterPushSubscription = (subscription, reminderTime) => {
  return dispatch => {
    return api.registerPushSubscription(subscription, reminderTime)
    .then(data => validateAPIResponse(data, dispatch))
    .then((data) => dispatch(confirmPushSubscription(data.subscription)))
    .catch(noop);
  };
};

export const syncUnregisterPushSubscription = subscription => {
  return dispatch => {
    return api.unregisterPushSubscription(subscription)
    .then(data => validateAPIResponse(data, dispatch))
    .then(() => dispatch(confirmPushUnsubscribed()))
    .catch(noop);
  };
};

export const syncSetReminderTime = (subscription, dayPart) => {
  return dispatch => {
    return api.setReminderTime(subscription, dayPart)
    .then(data => validateAPIResponse(data, dispatch))
    .then(() => dispatch(confirmSetReminderTime(dayPart)))
    .catch(noop);
  };
};

export const confirmEmail = (confirmationCode) => {
  return dispatch => {
    return api.confirmEmail(confirmationCode)
    .then(data => validateAPIResponse(data, dispatch))
    .then(() => dispatch(emailConfirmed()))
    .catch(noop);
  };
};

export const addCard = (token) => {
  return dispatch => {
    return api.addCard(token)
    .then(data => validateAPIResponse(data, dispatch))
    .then((card) => dispatch(confirmCardAdded(card)))
    .catch(noop);
  };
};

export const removeCard = (card) => {
  return dispatch => {
    return api.removeCard(card.id)
    .then(data => validateAPIResponse(data, dispatch))
    .then(() => dispatch(confirmCardRemoved(card.id)))
    .catch(noop);
  };
};

export const updateUserPlan = (subscriptionPlan) => {
  return dispatch => {
    return api.updateUserPlan(subscriptionPlan)
    .then(data => validateAPIResponse(data, dispatch))
    .then((data) => dispatch(confirmUserPlanUpdated(data)))
    .catch(noop);
  };
};

export const cancelSubscription = () => {
  return dispatch => {
    return api.cancelSubscription()
    .then(data => validateAPIResponse(data, dispatch))
    .then((data) => dispatch(confirmSubscriptionCanceled(data)))
    .catch(noop);
  };
};

export const requestPasswordReset = (email) => {
  return () => {
    return api.requestPasswordReset(email);
  };
};

export const resetPassword = (newPassword, nonce, resetCode) => {
  return () => {
    return api.updatePassword({
      nonce,
      resetCode,
      newPassword
    });
  };
};

export const unsubscribeFromEmails = (nonce) => {
  return () => {
    return api.unsubscribeFromEmails(nonce);
  };
};

export const fetchLatestVersion = () => {
  return dispatch => {
    return api.fetchLatestVersion()
    .then(data => validateAPIResponse(data, dispatch))
    .then(data => dispatch(receiveLatestVersion(data)))
    .catch(noop);
  };
};

export const dismissPromo = (id, stringId) => {
  return dispatch => {
    return api.dismissPromo(id)
    .then(data => validateAPIResponse(data, dispatch))
    .then(promo => dispatch(confirmPromoDismiss({...promo, stringId })))
    .catch(noop);
  };
};

export const recordPromoImpresion = (promo) => {
  return dispatch => {
    return api.recordPromoImpresion(promo)
    .then(data => validateAPIResponse(data, dispatch))
    .then(promo => dispatch(confirmPromoImpression(promo)))
    .catch(noop);
  };
};

export const fetchSuggestedPhotos = (query, variables) => {
  return dispatch => {
    return api.graphql(query, variables)
    .then(data => validateAPIResponse(data, dispatch))
    .catch(noop);
  }
}

export const saveDraft = (draftText) => {
  return dispatch => {
    api.saveDraft(draftText);
    dispatch(draftSaved(draftText));
  };
};

export const fetchPrompt = (query) => {
  return dispatch => {
    return api.graphql(query)
    .then(data => validateAPIResponse(data, dispatch))
    .catch(noop);
  };
};