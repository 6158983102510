import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import Button from './lib/Button';
import {
  breakpoints,
  getColor,
  headerHeight
} from '../css/primitives';

const packageVersion = process.env.REACT_APP_VERSION;
const currentVersion = (() => {
  const parts = packageVersion.split('.');
  return {
    major: parseInt(parts[0], 10),
    minor: parseInt(parts[1], 10),
    patch: parseInt(parts[2], 10)
  };
})();

const styles = StyleSheet.create({
  container: {
    boxSizing: 'border-box',
    padding: '12px',
    backgroundColor: getColor('green1', 0.1),

    [`@media (min-width: ${breakpoints.small})`]: {
      marginTop: `${headerHeight}px`
    }
  }
});

class NewVersionPrompt extends Component {
  static propTypes = {
    latestVersion: PropTypes.shape({
      major: PropTypes.number,
      minor: PropTypes.number,
      patch: PropTypes.number,
      version: PropTypes.string
    })
  }

  needsRefresh(version) {
    return currentVersion.major < version.major || currentVersion.minor < version.minor;
  }

  reload() {
    window.location.reload();
  }

  render() {
    return (this.props.latestVersion && this.needsRefresh(this.props.latestVersion)) ?
    (
      <div className={css(styles.container)}>
        A new version of Caneta is available. <Button strength="inline" onClick={this.reload}>Refresh</Button> to get the latest version.
      </div>
    ) : null;
  }
}

export default NewVersionPrompt;

