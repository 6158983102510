import * as actions from '../actionTypes';

const connectedApps = (
  state = {
    cache: {},
    addedTopics: []
  },
  action
) => {
  switch (action.type) {
  case actions.RECEIVE_TOPICS_FOR_QUERY:
    return {
      ...state,
      cache: {
        ...state.cache,
        [action.query]: action.topics.map(topic => ({id: topic.id, value: topic.name}))
      }
    };
  case actions.CONFIRM_TOPIC_CREATED:
    return {
      ...state,
      addedTopics: [
        ...state.addedTopics,
        action.topic
      ]
    };
  default:
    return state;
  }
};

export default connectedApps;
