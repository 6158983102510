import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { getColor } from '../../css/primitives';

// Based on Material Desgin and https://codepen.io/mrrocks/pen/EiplA

const animationDuration = '1.4s';
const offset = 187;

const rotate = {
  '0%': {
    transform: 'rotate(0deg)'
  },
  '100%': {
    transform: 'rotate(270deg)'
  }
};

const dash = {
  '0%': {
    strokeDashoffset: offset
  },
  '50%': {
    strokeDashoffset: offset/4,
    transform: 'rotate(135deg)'
  },
  '100%': {
    strokeDashoffset: offset,
    transform: 'rotate(450deg)'
  }
};

const styles = StyleSheet.create({
  spinner: {
    animationName: [rotate],
    animationDuration,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  },
  circle: {
    strokeDasharray: offset,
    strokeDashoffset: '0',
    transformOrigin: 'center',
    stroke: getColor('primary'),
    animationName: [dash],
    animationDuration,
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out'
  }
});


const Spinner = (props) => {
  const size = (props && props.size) || 66;
  const strokeWidth = size <= 48 ? '3' : '6';
  const className = props.className
    ? `${props.className} ${css(styles.spinner)}`
    : css(styles.spinner);
  return props.error ?
    (
      <div>Something isn't right. Please reload the page to give it another go.</div>
    ) :
    props.pastDelay ?
      (
      <svg
        className={className}
        width={size + 1} height={size + 1}
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={css(styles.circle)}
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx={size/2}
          cy={size/2}
          r={size/2 - 3}
        ></circle>
      </svg>
    ) : null;
};

export default Spinner;
